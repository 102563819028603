import { FC, useState, useEffect, useContext } from "react";
import {
  Container,
} from "@mui/material";
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { VisualizedMenus } from "../components/VisualizedMenus";
import { getGpaScoreDataRef, useAuth0Token } from "../common/http-requests";
import { gpaRequestType, gpaDataRefResponseType } from "../common/type";
import { Store } from "../store";

const GPADataRefPage: FC = () => {
  const [isSending, setIsSending] = useState(true);
  const { getToken } = useAuth0Token();
  const { state } = useContext(Store);

  // データ例
  // "data_ref": {
  //   "earned_periods": [2019, 2019, 2020, 2020, 2021, 2021],
  //   "earned_grades": [1, 1, 2, 2, 3, 3],
  //   "earned_semester_codes": ['1', '2', '1', '2', '1', '2'],
  //   "myself_scores": [1.0, 1.1, 1.2, 1.3, 1.4, 1.5],
  //   "all_students_scores": [2.5, 2.4, 2.3, 2.2, 2.1, 2.0],
  // }
  // ・修得した期の分だけ配列が返ります。
  // ・英語表記の対応を考慮して、backend API からは日本語表記を返しません。
  // ・earned_periods は年度を、earned_grades は年次を、earned_semester_codes は「1:前期, 2:後期」を表します。
  // ・myself_scores が自分の成績を、all_students_scores が全学生平均の成績を表します。
  const [gpaScoreDataRefData, setGpaScoreDataRefData] = useState<gpaDataRefResponseType>({
    data_ref: {}
  });

  // api取得失敗時のアラート
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });

  // clickawayした際、api取得失敗時のアラートを行わない。
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 以下は、記録一覧画面のコードを流用した。
  // Phase1と異なり、画面遷移が必要ではないため、location（uselocation）は使用しない。
  // React18(2022年)より、strictMode（javascriptのコードを通常より厳しくエラーチェックできるモード）において
  // オフスクリーンから再度表示されることで、useEffectが２回発火（キック）される仕様変更となっため、1回に制限するよう変更した。
  // また、以下のコードの})();の();は、定義した関数を即時実行している。
  // 最後にreturn文にて定義された関数は、クリーンアップ関数であり、リスナー、オブザーバー等のオブジェクトを設定した場合、
  // レンダリング時に毎回インスタンスを作成されるため、メモリーリーク及びメモリーの圧迫を抑止するための関数。
  // useEffectの依存配列（第2引数）は、通常、空リストもしくは、具体的な変数のリストを登録するのが一般的であり、
  // 定義しない場合は、FCのstate, propsのうちいずれかが更新される度に実行されてしまうため、通常、定義しないことはない。
  let alreadyFiredOnce = false;
  useEffect(() => {
    (async () => {
      if (!alreadyFiredOnce) {
        await getGpaScoreData(
          Number(state.userId),
        );
      }
    })();
    return () => {
      alreadyFiredOnce = true;
    };
  }, []);

  const getGpaScoreData = async (
    login_user_id: number, // userResponseType.id の値
  ) => {
    setIsSending(true);
    try {
      const requestParams: gpaRequestType = {
        login_user_id,
      };
      const data: gpaDataRefResponseType = await getGpaScoreDataRef(
        await getToken(),
        requestParams
      );
      setGpaScoreDataRefData(data);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "データを取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="成績情報を取得中..." />
          </>
        ) : (
          <>
            <VisualizedMenus />
            ※実装中：単位画面
            {/* 鈴木さん、実装をお願いします。 */}
          </>
        )}
      </Container>
    </>
  );
};

export default GPADataRefPage;
