import {
  Box,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import React, { FC, useContext, useEffect, useState } from "react";
import {
  useAuth0Token,
} from "../common/http-requests";
import { PaddingAtom } from "../components/atoms/PaddingAtom";
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { InputFormMolecule } from "../components/molecules/InputFormMolecule";
import { Store } from "../store";

const StudentInformationPage: FC = () => {
  const { getToken } = useAuth0Token();
  const { state, dispatch } = useContext(Store);
  const [isSending, setIsSending] = useState(true);

  // エラーメッセージ表示
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  const handleFeedbackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // 各フィールドの状態を管理
  const [userStudentNumber, setUserStudentNumber] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [department, setDepartment] = useState<string>("");       // 学部／研究科
  const [major, setMajor] = useState<string>(""); // 学科／専攻
  const [course, setCourse] = useState<string>("");         // 課程／コース
  const [degree, setDegree] = useState<string>("");         // 学位／称号
  const [teachingLicense, setTeachingLicense] = useState<string>(""); // 教員免許

  const getUserData = async () => {
    setIsSending(true);
    try {
      setUserStudentNumber(state.userStudentNumber || ""); // undefinedの場合は空文字列を代入
      setUserName(state.userName || "");
      setDepartment(state.userDepartment || "");
      setMajor(state.userMajor || "");
      setCourse(state.userCourse || "");
      setDegree(state.userDegree || "");
      setTeachingLicense(state.userTeachingLicense || "");
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "ユーザ情報を取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        {
          <FeedbackBar
            feedbackInfo={feedbackInfo}
            handleClose={handleFeedbackClose}
          ></FeedbackBar>
        }
        <Typography
          variant="h5"
          style={{ marginTop: 10, textAlign: "center" }}
        >
          <PersonIcon />　学籍情報
        </Typography>

        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="ユーザ情報を取得中..." />
          </>
        ) : (
          <>
            <Box sx={{ padding: "20px 0 20px 0" }}>
              <Box sx={{ width: "100%", marginX: "auto" }}>
                {/* 学籍番号（編集不可） */}
                <InputFormMolecule inputLabel="学籍番号">
                  <TextField
                    id="studentNumber"
                    label="学籍番号"
                    variant="outlined"
                    value={userStudentNumber}
                    style={{ width: "40%" }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </InputFormMolecule>

                {/* 氏名 */}
                <InputFormMolecule inputLabel="氏名">
                  <TextField
                    id="userName"
                    label="氏名"
                    variant="outlined"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    style={{ width: "40%" }}
                  />
                </InputFormMolecule>

                {/* 学部／研究科 */}
                <InputFormMolecule inputLabel="学部／研究科">
                  <TextField
                    id="department"
                    label="学部／研究科"
                    variant="outlined"
                    value={department}
                    onChange={(e) => setDepartment(e.target.value)}
                    style={{ width: "40%" }}
                  />
                </InputFormMolecule>

                {/* 学科／専攻 */}
                <InputFormMolecule inputLabel="学科／専攻">
                  <TextField
                    id="major"
                    label="学科／専攻"
                    variant="outlined"
                    value={major}
                    onChange={(e) => setMajor(e.target.value)}
                    style={{ width: "40%" }}
                  />
                </InputFormMolecule>

                {/* 課程／コース */}
                <InputFormMolecule inputLabel="課程／コース">
                  <TextField
                    id="course"
                    label="課程／コース"
                    variant="outlined"
                    value={course}
                    onChange={(e) => setCourse(e.target.value)}
                    style={{ width: "40%" }}
                  />
                </InputFormMolecule>

                {/* 学位／称号 */}
                <InputFormMolecule inputLabel="学位／称号">
                  <TextField
                    id="degree"
                    label="学位／称号"
                    variant="outlined"
                    value={degree}
                    onChange={(e) => setDegree(e.target.value)}
                    style={{ width: "40%" }}
                  />
                </InputFormMolecule>

                {/* 教員免許 */}
                <InputFormMolecule inputLabel="教員免許">
                  <TextField
                    id="teachingLicense"
                    label="教員免許"
                    variant="outlined"
                    value={teachingLicense}
                    onChange={(e) => setTeachingLicense(e.target.value)}
                    style={{ width: "40%" }}
                  />
                </InputFormMolecule>

                <hr />
                <PaddingAtom />
              </Box>
            </Box>
          </>
        )}
      </Container >
    </>
  );
};

export default StudentInformationPage;
