import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import React, { FC, useContext, useEffect, useState } from "react";
import {
  updateUser,
  useAuth0Token,
} from "../common/http-requests";
import {
  updateUserRequestType,
} from "../common/type";
import { checkValidUserId } from "../common/util";
import { PaddingAtom } from "../components/atoms/PaddingAtom";
import { CircularProgressMolecule } from "../components/molecules/CircularProgressMolecule";
import { FeedbackBar } from "../components/molecules/FeedbackBar";
import { InputFormMolecule } from "../components/molecules/InputFormMolecule";
import { Store } from "../store";
import types from "../store/types";

const MaxTextLength_UserName = 100;
const SecretPasswordText = "********";

const AccountPage: FC = () => {
  const { getToken } = useAuth0Token();
  const { state, dispatch } = useContext(Store);
  const [isSending, setIsSending] = useState(true);

  // エラーメッセージ表示
  const [feedbackInfo, setFeedbackInfo] = useState({
    open: false,
    text: "",
    type: "success" as "error" | "success" | "warning" | "info",
    autoHideDuration: null,
  });
  const handleFeedbackClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackInfo({ ...feedbackInfo, open: false });
  };

  // ユーザID（学籍番号８桁、編集不可）
  const [userStudentNumber, setUserStudentNumber] = useState<string>("");
  // パスワード（編集不可）
  // TODO: ログインパスワードを「＊（非表示）」と表示切替できること
  const [userPassword, setUserPassword] = useState<string>("");
  // ユーザ名（編集可）
  const [userName, setUserName] = useState<string>("");

  const getUserData = async () => {
    setIsSending(true);
    try {
      setUserStudentNumber(state.userStudentNumber);
      setUserPassword(SecretPasswordText); // TODO：Auth0 またはシボレス認証からパスワードを取得する？
      setUserName(state.userName);
    } catch {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "ユーザ情報を取得できませんでした。時間をおいてまた試してください。",
        type: "error",
      });
    } finally {
      setIsSending(false);
    }
  };

  // ユーザ情報を更新
  const saveUserData = async () => {
    setIsSending(true);
    try {
      // ユーザIDの妥当性チェック
      if (! await checkValidUserId(state.userId, state.userEmail, getToken, setFeedbackInfo, feedbackInfo)) {
        return;
      }
      // 画面データの妥当性チェック
      if (! await checkValidUserData()) {
        return;
      }

      const requestParams: updateUserRequestType = {
        login_user_id: state.userId,
        user_name: userName,
      };
      await updateUser(await getToken(), requestParams);

      dispatch({
        type: types.SET_USER_INFO,
        payload: {
          userId: state.userId,
          userEmail: state.userEmail,
          userName: userName,
          userType: state.userType,
          userStudentNumber: state.userStudentNumber,
          userDepartment: state.userDepartment,
          userMajor: state.userMajor,
          userCourse: state.userCourse,
          userDegree: state.userDegree,
          userTeachingLicense: state.userTeachingLicense,
        },
      });
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "保存しました",
        type: "success",
      });
    } catch {
    } finally {
      setIsSending(false);
    }
  };

  const checkValidUserData = async () => {
    // ユーザ名 テキストフィールド
    if ((userName === "") || (userName.length > MaxTextLength_UserName)) {
      setFeedbackInfo({
        ...feedbackInfo,
        open: true,
        text: "ユーザ名は100文字以内で入力してください。",
        type: "error",
      });
      return false;
    }

    return true;
  };

  // 保存ボタン
  const handleClickSaveButton = async () => {
    await saveUserData();
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <>
      <Container maxWidth="xl">
        {
          <FeedbackBar
            feedbackInfo={feedbackInfo}
            handleClose={handleFeedbackClose}
          ></FeedbackBar>
        }
        <Typography
          variant="h5"
          style={{ marginTop: 10, textAlign: "center" }}
        >
          <PersonIcon />　アカウント
        </Typography>

        {isSending ? (
          <>
            <CircularProgressMolecule displayLabel="ユーザ情報を取得中..." />
          </>
        ) : (
          <>
            <Box sx={{ padding: "20px 0 20px 0" }}>
              <Box sx={{ width: "100%", marginX: "auto" }}>
                {/* ユーザID（学籍番号８桁、編集不可） */}
                <InputFormMolecule inputLabel="ID">
                  <TextField
                    id="studentNumber"
                    label="学籍番号"
                    variant="outlined"
                    value={userStudentNumber}
                    style={{ width: "40%" }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </InputFormMolecule>

                {/* パスワード（編集不可） */}
                <InputFormMolecule inputLabel="Password">
                  <TextField
                    id="password"
                    label="パスワード"
                    variant="outlined"
                    value={userPassword}
                    style={{ width: "40%" }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </InputFormMolecule>

                {/* ユーザ名（編集可） */}
                <InputFormMolecule inputLabel="UserName">
                  <TextField
                    id="userName"
                    label="ユーザ名"
                    variant="outlined"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    style={{ width: "40%" }}
                  />
                </InputFormMolecule>

                <hr />
                <PaddingAtom />
              </Box>
              <Box sx={{ width: "80%", marginX: "auto", textAlign: "center" }}>
                <Button
                  style={{ width: "100px", zIndex: 0 }}
                  variant="contained"
                  onClick={() => handleClickSaveButton()}
                >
                  保存
                </Button>
              </Box>
            </Box>
          </>
        )}
      </Container >
    </>
  );
};

export default AccountPage;
